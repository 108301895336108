import { CCard, CCardBody, CCardHeader, CImg } from "@coreui/react";

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import api from "src/utils/api";
import Divider from "../Branch/settlement/components/Divider";
import Spinner from "../layout/Spinner";
import Timeline from "./Timeline";

const OrderSms = () => {
  const { id } = useParams();
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    getDetails();
  }, [id]);

  const getDetails = async () => {
    try {
      const res = await api.get(`/order/orderdetails?id=${id}`);
      if (res.data.success) {
        setDetails(res.data.data);
        setLoading(false);
      }
    } catch (err) {
      history.push("/");
      setLoading(false);
    }
  };

  const getStatus = (orderStatus) => {
    switch (orderStatus) {
      case "placed":
        return 1;
      case "accepted":
        return 2;
      case "preparing":
        return 3;
      case "ready":
        return 4;
      case "on delivery":
        return 5;
      case "delivered":
        return 6;
      case "cancelled":
        return 7;

      default:
        break;
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div
          style={{
            background: `url('/images/offlinebg.jpg')`,
            backgroundSize: "contain",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              paddingTop: "50px",
              marginBottom: "20px",
            }}
          >
            <CImg src="/images/logo/loginlogo.png" width="65" height="70" />
            <h5 style={{ color: "#333333", fontSize: "20px" }}>
              Welcome to <span style={{ fontWeight: "bold" }}>Fuddo</span>
            </h5>
            <span
              style={{ color: "black", marginTop: "25px", fontWeight: "500" }}
            >
              Track your order status
            </span>

            <CCard
              style={{
                borderRadius: "10px",
                boxShadow: "#cfcfcf 0px 0px 13px 2px",
                width: "280px",
                marginTop: "10px",
              }}
            >
              <CCardHeader
                style={{
                  backgroundColor: "#0A9650",
                  color: "white",
                  borderRadius: "10px 10px 0px 0px",
                }}
              >
                Order ID :{" "}
                <small style={{ fontWeight: "bold", fontSize: "14px" }}>
                  {details.orderId}
                </small>
              </CCardHeader>
              <CCardBody>
                <div
                  style={{
                    backgroundColor: "#e9e9e9",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
                  {details?.offlineCustomer?.name}
                  <br />
                  <span style={{ fontWeight: "bold" }}>Mobile: </span>{" "}
                  {details?.contactNumber}
                </div>

                <h5 className="mt-4">Items</h5>
                <Divider width={30} />
                {details?.items.map((item) => {
                  return (
                    <>
                      <span>
                        {item.name} X {item.qty}
                      </span>
                      <br />
                    </>
                  );
                })}
                <h6
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "30px",
                  }}
                >
                  Delivery Charge : <div> ₹{details?.deliveryCharge} </div>
                </h6>
                <h6
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Discount :{" "}
                  <div> ₹{details?.promoApplied?.discount || 0} </div>
                </h6>
                <h6
                  style={{
                    fontWeight: "600",
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Total : <div> ₹{details.totalAmount} </div>
                </h6>
                <Timeline value={getStatus(details.orderStatus)} />
              </CCardBody>
            </CCard>
          </div>
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <div className="mb-2">
                <span style={{ fontWeight: "500" }}>
                  Order online on Fuddo to for exciting offers
                </span>
              </div>
              <a href="https://apps.apple.com/in/app/fuddo/id1565276172">
                <CImg
                  src="/images/appstore.png"
                  width="150"
                  height="50"
                  className="mr-5"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.backb.fuddo&hl=en_IN&gl=US"
                target="_blank"
              >
                <CImg src="/images/playstore.png" width="150" height="50" />
              </a>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "rgb(102 102 102)",
                color: "white",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <a
                href="https://backb.in"
                style={{ color: "white", textDecoration: "none" }}
              >
                <h6 className="mb-0">Made with &#10084;&#65039; by BackB</h6>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderSms;
