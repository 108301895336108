import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import Alert from "./components/views/Alert";
import { LOGOUT } from "./modules/User/constants";

//REDUX
import store from "./store";
import { loadUser } from "./modules/User/actions";
import setAuthToken from "./utils/setAuthToken";

//Login
import Login from "./components/login/Login";

// Tracking
import OrderSms from "./components/tracking/OrderSms";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

//Layouts
const TheLayout = React.lazy(() => import("./components/containers/TheLayout"));

const App = ({ user }) => {
  useEffect(() => {
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Alert />
        <Switch>
          <Route
            path="/tracking/:id"
            name="Offline Tracking"
            render={(props) => <OrderSms {...props} />}
          />
          <Route
            exact
            path="/"
            name="Home"
            render={(props) => <Login {...props} />}
          />

          <Route name="Home" render={(props) => <TheLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
